import React from 'react'
import MedicineTemplate from 'components/templates/MedicineTemplate/MedicineTemplate'

export default function MedicineLaboratoryAnalysisPage() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const productName = 'Analisi Laboratorio'

  const description =
    'ING GROUP mette a disposizione <b>un ricco elenco di test e analisi</b> di supporto alla medicina del lavoro secondo differenti <b>aree applicative</b> per venire incontro a tutte esigenze attraverso un servizio di qualità, rapido ed efficace, articolato con svariate strutture su tutto il territorio nazionale.<br><br> A titolo esemplificativo e non esaustivo:<br> • Prelievo ematico;<br> • Emocromo;<br> • Glicemia;<br> • Creatinina;<br> • Transaminasi;<br> • Gamma GT;<br> • Trigliceridi;<br> • Drug test;<br> • Alcool Test.'

  return (
    <>
      <MedicineTemplate seoKeywords={seoKeywords} productName={productName} description={description} />
    </>
  )
}
